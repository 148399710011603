import { Component, Fragment } from 'react'
import { ContentfulContext } from '../../../contexts/contentful.context'

import { ContentTypeEnum } from '../../../enums/content-type.enum'
import { IContentfulClient } from '../../../interfaces'
import {
    ITechnology,
    ITechnologyCf,
} from '../../../interfaces/technology.interface'
import { ContentfulUtil } from '../../../utils'

type TechnologyProps = { technologies: ITechnology[] }
type TechnologyState = { technologiesMap: Map<string, ITechnology> }

export class Technology extends Component<TechnologyProps, TechnologyState> {
    static contextType = ContentfulContext

    componentDidMount() {
        const { technologies } = this.props
        const helper = new ContentfulUtil()
        let contentful: IContentfulClient = this.context as IContentfulClient
        let normalizedMap = new Map<string, ITechnology>()

        contentful
            .getEntries<ITechnologyCf[]>(ContentTypeEnum.TECHNOLOGY, {
                idList: technologies.map((e) => e.id),
            })
            .then((list) => {
                list.forEach((item) => {
                    const normalized = helper.normalizeEntry<ITechnology>(item)
                    normalizedMap.set(normalized.slug, normalized)
                })
                this.setState({
                    technologiesMap: normalizedMap,
                })
            })
    }

    BoxInfo(props) {
        const { icon, title, description } = props
        return (
            <Fragment>
                <img src={icon.url} alt="tecnología" />
                <span className="uppercase font-bold text-white text-3xl mt-4 block">
                    {title}
                </span>
                <p className="text-white text-lg mt-9 ">{description}</p>
            </Fragment>
        )
    }

    render() {
        return (
            this.state?.technologiesMap &&
            this.state.technologiesMap.size ===
                this.props.technologies.length && (
                <section
                    id="que-hacemos"
                    className="page-section rain relative py-5 lg:py-20 px-8 lg:px-16"
                >
                    <span className="topline block absolute top-0 left-0 w-full"></span>

                    <div className="mx-auto max-w-screen-2xl">
                        <h2
                            className="text-white text-4xl uppercase font-bold pt-10"
                            data-aos="fade-left"
                        >
                            ¿qué hacemos?
                        </h2>
                        <h3
                            className="text-white text-2xl mt-5 lg:ml-20"
                            data-aos="fade-left"
                            data-aos-duration="1000"
                        >
                            Diseñamos y construimos aplicaciones móviles, web e
                            infraestructura.
                        </h3>

                        <article className="flex flex-col lg:flex-row">
                            <section
                                className="p-10 mt-20 lg:ml-20 bg-indigo-900 w-full lg:w-96 h-auto lg:h-80 rounded-xl backdrop-filter backdrop-blur bg-opacity-60"
                                data-aos="fade-up"
                            >
                                <this.BoxInfo
                                    {...this.state.technologiesMap.get(
                                        'frontend'
                                    )}
                                />
                            </section>

                            <section
                                className="p-10 mt-10 lg:mt-20 lg:ml-10 bg-indigo-900 w-full lg:w-96 block h-auto lg:h-80 rounded-xl backdrop-filter backdrop-blur bg-opacity-60"
                                data-aos="fade-up"
                            >
                                <this.BoxInfo
                                    {...this.state.technologiesMap.get(
                                        'backend'
                                    )}
                                />
                            </section>
                        </article>

                        <article className="flex flex-col lg:flex-row">
                            <section
                                className="p-10 mt-10 lg:mt-10 lg:ml-48 bg-indigo-900  w-full lg:w-96 h-auto lg:h-80 rounded-xl backdrop-filter backdrop-blur bg-opacity-60"
                                data-aos="fade-up"
                            >
                                <this.BoxInfo
                                    {...this.state.technologiesMap.get('iot')}
                                />
                            </section>

                            <section
                                className="p-10 mt-10 lg:mt-10 lg:ml-10 bg-indigo-900 w-full lg:w-96 block h-auto lg:h-80 rounded-xl backdrop-filter backdrop-blur bg-opacity-60"
                                data-aos="fade-up"
                            >
                                <this.BoxInfo
                                    {...this.state.technologiesMap.get(
                                        'design'
                                    )}
                                />
                            </section>
                        </article>
                    </div>
                </section>
            )
        )
    }
}
