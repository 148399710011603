import { Component } from 'react'
import { IAboutUs } from '../../../interfaces/about-us.interface'
import { ContentfulUtil } from '../../../utils'

import './AboutUs.css'

type AboutUsState = {
    descriptionProccesed: string
}

export class AboutUs extends Component<IAboutUs, AboutUsState> {
    constructor(props) {
        super(props)
        this.state = {
            descriptionProccesed: '',
        }
    }

    componentDidMount() {
        const { description } = this.props

        const helper = new ContentfulUtil()
        const descriptionProccesed = helper.richTextToHTML(description)

        this.setState({
            descriptionProccesed,
        })
    }

    render() {
        return (
            <section
                id="nosotros"
                className="page-section relative px-16 py-20"
            >
                <span className="topline block absolute top-0 left-0 w-full"></span>

                <div className="flex flex-col lg:flex-row justify-between mt-0 lg:pt-10 mx-auto max-w-screen-2xl">
                    <div className="w-full lg:w-1/2 flex flex-col justify-center">
                        <h2
                            className="uppercase text-white text-4xl font-bold"
                            data-aos="fade-right"
                            data-aos-duration="1000"
                        >
                            {this.props.title}
                        </h2>
                        <span
                            className="block underline mt-5 lg:mt-10 w-40"
                            data-aos="fade-left"
                            data-aos-duration="2000"
                        ></span>
                    </div>

                    <div
                        className="text-white w-full lg:w-1/2 lg:pr-24 mt-10 lg:mt-0"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                    >
                        <div
                            className="rich-text"
                            dangerouslySetInnerHTML={{
                                __html: this.state.descriptionProccesed,
                            }}
                        ></div>
                    </div>
                </div>
            </section>
        )
    }
}
