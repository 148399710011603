import { Component } from 'react'

import './Footer.css'

import { IFooter } from '../../../interfaces/footer.interfaces'

import linkedin from '../../../images/linkedin.svg'
import facebook from '../../../images/facebook.svg'
import instagram from '../../../images/instagram.svg'

export class Footer extends Component<IFooter> {
    render() {
        return (
            <section
                id="contacto"
                className="page-section footer relative px-16 pt-14 pb-20"
            >
                <span className="topline block absolute top-0 left-0 w-full"></span>

                <div className="mx-auto max-w-screen-2xl">
                    <div data-aos="fade-right" data-aos-duration="1000">
                        <h2 className="uppercase text-white text-2xl sm:text-4xl">
                            materialicemos tus ideas
                        </h2>
                        <span
                            className="block underline mt-3 w-20"
                            data-aos="fade-left"
                            data-aos-duration="2000"
                        ></span>
                    </div>

                    <div
                        className="flex flex-col md:flex-row justify-between pt-12 "
                        data-aos="fade-right"
                        data-aos-duration="1000"
                    >
                        <div>
                            <h3 className="text-xl capitalize text-pink-500">
                                contáctanos
                            </h3>
                            <address className="text-white text-sm mt-2 not-italic">
                                <a
                                    href={`mailto:${this.props.email}`}
                                    className="block"
                                >
                                    {this.props.email}
                                </a>

                                {this.props.addresses.map((address, index) => (
                                    <span className="block mt-1" key={index}>
                                        {address}
                                    </span>
                                ))}
                            </address>
                        </div>

                        <div className="lg:pr-20 text-left mt-10 md:mt-0">
                            <h3 className="text-xl capitalize text-pink-500 mb-2 text-left">
                                síguenos
                            </h3>

                            <a
                                className="inline-block mr-5"
                                href={this.props.linkedin}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={linkedin} alt="linkedin" />
                            </a>
                            <a
                                className="inline-block mr-5"
                                href={this.props.facebook}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={facebook} alt="facebook" />
                            </a>
                            <a
                                className="inline-block mr-5"
                                href={this.props.instagram}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={instagram} alt="instagram" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
