import { Asset, createClient, Entry } from 'contentful'
import { ContentTypeEnum } from '../enums/content-type.enum'
import { IContentfulClient } from '../interfaces'

export default class ContentfulApi implements IContentfulClient {
    private accessToken
    private environment
    private host
    private space

    constructor() {
        this.accessToken = 'bcaDd4YUEYumcyeUy7zjKfvinfzZG87LCbjzIRQZIok' || ''
        this.environment = 'master' || ''
        this.host = 'cdn.contentful.com' || ''
        this.space = 'p7u22nizwwt3' || ''
    }

    private client = () => {
        const contentfulConfig = {
            accessToken: this.accessToken,
            environment: this.environment,
            host: this.host,
            space: this.space,
        }

        return createClient(contentfulConfig)
    }

    getEntry<T>(id: string): Promise<T> {
        return new Promise((resolve, reject) => {
            this.client()
                .getEntry<T>(id)
                .then((entry) => resolve(entry as any))
                .catch(reject)
        })
    }

    /**
     * @description Obtiene entradas desde contentful
     * @param contentType Tipo de entrada que se desea recuperar
     * @param filter Filtros a la busqueda
     * @returns listado de entries del tipo buscado
     */
    getEntries<T>(
        contentType: ContentTypeEnum,
        filter?: { idList: string[] }
    ): Promise<Entry<T>[]> {
        let query: any = {
            content_type: contentType,
        }
        let filterBuild = {}

        if (filter) {
            if (filter.idList) {
                filterBuild = {
                    ...filterBuild,
                    'sys.id[in]': filter.idList.toString(),
                }
            }
        }

        query = { ...query, ...filterBuild }

        return new Promise((resolve, reject) => {
            this.client()
                .getEntries<T>(query)
                .then((entries) => resolve(entries.items))
                .catch(reject)
        })
    }

    /**
     * @description Obtiene archivos multimedia desde contentful
     * @param assetId
     * @returns un promesa con un asset
     */
    getAsset(assetId: string): Promise<Asset> {
        return new Promise((resolve, reject) => {
            this.client()
                .getAsset(assetId)
                .then((asset) => {
                    asset.fields.file.url = 'https:' + asset.fields.file.url
                    resolve(asset)
                })
                .catch((e) => reject(e))
        })
    }

    /**
     * @description Obtiene archivos multimedia desde contentful
     * @param assetList Listado de ids de assets a recuperar
     * @returns un promesa con un listado de asset
     */
    getAssets(assetList: string[]): Promise<Asset[]> {
        return new Promise((resolve, reject) => {
            if (assetList.length === 0) {
                reject('ids not found')
                return
            }

            this.client()
                .getAssets({ 'sys.id[in]': assetList.toString() })
                .then((assets) => {
                    resolve(
                        assets.items.map((asset) => {
                            asset.fields.file.url =
                                'https:' + asset.fields.file.url
                            return asset
                        })
                    )
                })
                .catch((e) => reject(e))
        })
    }
}
