import { Component } from 'react'

import { ContentfulContext } from '../../../contexts/contentful.context'
import { IContentfulClient } from '../../../interfaces'
import { IInnovation } from '../../../interfaces/innovation.interface'

import Slider from 'react-slick'

type InnovationState = {
    imagesNormalized: string[]
}

export class Innovation extends Component<IInnovation, InnovationState> {
    static contextType = ContentfulContext

    constructor(props) {
        super(props)
        this.state = {
            imagesNormalized: [],
        }
    }

    componentDidMount() {
        const { images = [] } = this.props
        let contentful: IContentfulClient = this.context as IContentfulClient

        contentful.getAssets(images.map((e) => e.id)).then((assets) => {
            const normalized = assets.map((asset) => asset.fields.file.url)
            this.setState({ imagesNormalized: normalized })
        })
    }

    render() {
        const settings = {
            className: 'slider variable-width',
            dots: false,
            infinite: true,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            autoplay: true,
            speed: 1000,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        variableWidth: false,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        variableWidth: false,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: false,
                    },
                },
            ],
        }

        return (
            this.state.imagesNormalized.length > 0 && (
                <section
                    id="innovacion"
                    className="page-section rain relative py-20 bg-no-repeat"
                >
                    <span className="topline block absolute top-0 left-0 w-full"></span>

                    <h2 className="text-white text-4xl uppercase font-bold text-center pt-10">
                        innovación
                    </h2>

                    <Slider {...settings} className="mt-20">
                        {this.state.imagesNormalized.map((image, i) => (
                            <div key={i}>
                                <div className="bg-indigo-900 w-11/12 h-auto rounded-xl backdrop-filter backdrop-blur bg-opacity-60 py-16 px-8 relative">
                                    <img
                                        src={image}
                                        className="w-full"
                                        alt="test"
                                        style={{
                                            maxHeight: '104px',
                                            height: 'auto',
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </Slider>
                </section>
            )
        )
    }
}
