import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { Entry } from 'contentful'

export class ContentfulUtil {
    /**
     * @description Transforma el Field Rich Text a HTML
     * @param content Rich Text content
     * @returns HTML
     */
    richTextToHTML(content: any): string {
        return documentToHtmlString(content)
    }

    /**
     * @description Permite obtener un Entry más limpio.
     *              Se eliminan campos no utilizados, se devuelve en una interfaz definida,
     *              normaliza assets
     * @param entry Entry a normalizar
     * @returns Devuelve un nuevo objeto normalizado
     */
    normalizeEntry<T>(entry: Entry<any>): T {
        if (!entry || !entry.fields) {
            return {} as any
        }

        const entryFields = JSON.parse(JSON.stringify(entry.fields))

        entryFields.id = entry.sys.id

        Object.keys(entryFields).forEach((key) => {
            const field = entryFields[key]

            if (field && field.sys) {
                entryFields[key] = this.normalizeFields(field)
            } else if (Array.isArray(field)) {
                entryFields[key] = field.map((arrayEntry) => {
                    if (arrayEntry?.sys?.id) {
                        return {
                            id: arrayEntry.sys.id,
                        }
                    } else {
                        return arrayEntry
                    }
                })
            }
        })

        return entryFields
    }

    /**
     * @description Permite normalizar Entries hijos
     * @param field Listado de campos a normalizar
     * @returns Devuelve un field normalizado si corresponde
     */
    normalizeFields(entry: Entry<any>): any {
        const entryChild: Entry<any> = JSON.parse(JSON.stringify(entry))
        const { contentType, type } = entryChild.sys

        if (contentType) {
            return this.normalizeEntry(entryChild)
        } else if (type === 'Asset') {
            return this.normalizeAsset(entryChild.fields)
        }

        return entry
    }

    /**
     * @description Normaliza un asset
     * @param fields Objeto field de un Asset
     * @returns Objeto con la url del asset
     */
    normalizeAsset(fields: any): { url: string } {
        try {
            return {
                url: 'https:' + fields.file.url,
            }
        } catch (e) {
            console.error(e)
            return null
        }
    }
}
