import { Component } from 'react'

import { ContentTypeEnum } from '../../enums/content-type.enum'
import { IContentfulClient, IHome, IHomeCf } from '../../interfaces'
import { ContentfulUtil } from '../../utils'

import { Footer, Header, Loading } from '../common'
import {
    AboutUs,
    Customer,
    Home,
    Innovation,
    Services,
    Technology,
} from '../views/home'

import AOS from 'aos'

import 'aos/dist/aos.css'
import '../../theme/theme.css'
import './Layout.css'
import ContentfulApi from '../../api/ContentfulApi'
import { ContentfulContext } from '../../contexts/contentful.context'

type HomeState = {
    home: IHome
    contentful: IContentfulClient
}

class Layout extends Component<{}, HomeState> {
    constructor(props) {
        super(props)
        this.state = {
            home: null,
            contentful: null,
        }
    }

    componentDidMount() {
        AOS.init({
            delay: 200,
            once: false,
            mirror: false,
        })

        let contentful: IContentfulClient = new ContentfulApi()
        this.setState({
            contentful,
        })

        const helper = new ContentfulUtil()

        contentful.getEntries<IHomeCf[]>(ContentTypeEnum.HOME).then((list) => {
            const home = helper.normalizeEntry<IHome>(list[0])

            setTimeout(() => {
                this.setState({
                    home,
                })
            }, 1000)
        })
    }

    render() {
        return this.state.home ? (
            <ContentfulContext.Provider value={this.state.contentful}>
                <div className="overflow-hidden select-none">
                    <Header {...this.state.home.header} />
                    <main className="select-none">
                        <article>
                            <div className="home-wrapper">
                                <Home />
                                <Customer
                                    customers={this.state.home.customers}
                                />
                            </div>
                            <Technology
                                technologies={this.state.home.technologies}
                            />
                            <div className="about-wrapper">
                                <AboutUs {...this.state.home.aboutUs} />
                                <Services />
                            </div>
                            <Innovation {...this.state.home.innovation} />
                        </article>
                    </main>
                    <Footer {...this.state.home.footer} />
                </div>
            </ContentfulContext.Provider>
        ) : (
            <Loading />
        )
    }
}

export default Layout
