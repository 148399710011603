import { Component } from 'react'

import logoImg from '../../../images/logo-white.svg'

export class Loading extends Component {
    render() {
        return (
            <div className="flex flex-col justify-center items-center h-screen">
                <img
                    className="w-64 animate-bounce"
                    src={logoImg}
                    alt="Logo Idac"
                />
                <h1 className="text-center text-white text-2xl mt-8">
                    Cargando...
                </h1>
            </div>
        )
    }
}
