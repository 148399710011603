import { Component } from 'react'

import { IContentfulClient, ICustomer, ICustomerCf } from '../../../interfaces'
import { ContentfulContext } from '../../../contexts/contentful.context'

import { ContentTypeEnum } from '../../../enums/content-type.enum'
import { ContentfulUtil } from '../../../utils'

type CustomerProps = {
    customers: ICustomer[]
}

type CustomerState = {
    customersNormalized: ICustomer[]
}

export class Customer extends Component<CustomerProps, CustomerState> {
    static contextType = ContentfulContext

    constructor(props) {
        super(props)
        this.state = {
            customersNormalized: [],
        }
    }

    componentDidMount() {
        const { customers } = this.props
        const helper = new ContentfulUtil()
        let contentful: IContentfulClient = this.context as IContentfulClient

        contentful
            .getEntries<ICustomerCf[]>(ContentTypeEnum.CUSTOMER, {
                idList: customers.map((e) => e.id),
            })
            .then((list) => {
                const customers = list.map((item) =>
                    helper.normalizeEntry<ICustomer>(item)
                )

                setTimeout(() => {
                    this.setState({
                        customersNormalized: customers,
                    })
                }, 1500)
            })
    }

    render() {
        return (
            <section
                id="clientes"
                className="page-section mx-auto max-w-screen-2xl px-8 lg:px-16 pt-20 pb-8 lg:pb-20"
                data-aos="fade-left"
            >
                <h2 className="uppercase text-pink-500 text-center text-2xl pt-0 lg:pt-20">
                    hemos trabajado para
                </h2>
                <div className="pt-24 flex flex-row flex-wrap justify-around lg:justify-center items-center">
                    {this.state.customersNormalized.map((e, i) => (
                        <div className="mx-0 lg:mx-10 mb-10" key={i}>
                            <img
                                src={e.logo.url}
                                alt={e.name}
                                className="w-4/5 lg:w-full"
                                style={{ all: 'revert' }}
                            />
                        </div>
                    ))}
                </div>
            </section>
        )
    }
}
