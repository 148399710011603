import { Component } from 'react'

import { ContentfulUtil } from '../../../utils'
import { ContentTypeEnum } from '../../../enums/content-type.enum'
import { ContentfulContext } from '../../../contexts/contentful.context'
import {
    IContentfulClient,
    IHeaderLink,
    IHeaderLinkCf,
} from '../../../interfaces'
import { Link } from '../../common'

import logoImg from '../../../images/logo.svg'
import menuLineImg from '../../../images/menu-line.svg'
import closeLineImg from '../../../images/close-line.svg'

import './Header.css'

type HeaderProps = {
    linkList: IHeaderLink[]
}
type HeaderState = {
    isScrolling: boolean
    isNavigating: boolean
    isMenuOpen: boolean
    links: Element[]
    sectionPositions: number[]
    currentIndicatorPosition: number
    currentPageHeight: number
    linkList: IHeaderLink[]
}

export class Header extends Component<HeaderProps, HeaderState> {
    static contextType = ContentfulContext
    timeout: NodeJS.Timeout = null

    constructor(props: HeaderProps) {
        super(props)
        this.state = {
            isScrolling: false,
            isNavigating: false,
            isMenuOpen: false,
            links: [],
            sectionPositions: [],
            currentIndicatorPosition: 10,
            currentPageHeight: 0,
            linkList: [],
        }
    }

    componentDidMount() {
        const { linkList } = this.props

        let contentful: IContentfulClient = this.context as IContentfulClient
        const helper = new ContentfulUtil()

        contentful
            .getEntries<IHeaderLinkCf[]>(ContentTypeEnum.HEADER_LINK, {
                idList: linkList.map((e) => e.id),
            })
            .then((list) => {
                const links = list.map((item) => {
                    return helper.normalizeEntry<IHeaderLink>(item)
                })
                const orderedLinks = links.sort((a, b) => {
                    return a.order - b.order
                })

                this.setState(
                    {
                        linkList: orderedLinks,
                        currentPageHeight: document.body.offsetHeight,
                    },
                    () => {
                        this.configLinks()
                        this.configSections()

                        window.addEventListener('resize', () => this.onResize)
                        window.addEventListener('scroll', this.onScroll)
                    }
                )
            })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll)
        window.removeEventListener('resize', this.onResize)
        this.removeListeners()
    }

    configSections() {
        this.setState({
            sectionPositions: [],
        })

        const sections = document.getElementsByClassName('page-section')
        const positions: number[] = []
        for (let i = 0; i < sections.length; i++) {
            const section = sections.item(i)

            const top = (section as HTMLElement).offsetTop

            if (section) {
                positions.push(top)
            }
        }

        this.setState({ sectionPositions: positions })
    }

    configLinks() {
        this.setState({
            links: [],
        })

        const links = document.getElementsByClassName('navlink')

        const linkList = []
        for (let i = 0; i < links.length; i++) {
            const element = links.item(i)

            if (element) {
                linkList.push(element)

                if (i === 0) {
                    this.setState({
                        currentIndicatorPosition:
                            (element as HTMLAnchorElement).offsetLeft + 15,
                    })
                }

                element.addEventListener('mouseover', () =>
                    this.onHover(element)
                )
                element.addEventListener('click', () => this.onClick(element))
                element.addEventListener('mouseleave', () =>
                    this.onBlur(element)
                )
            }
        }

        this.setState({ links: linkList })
    }

    removeListeners() {
        this.state.links.forEach((element: Element) => {
            element.removeEventListener('mouseover', () => {
                this.onHover(element)
            })
            element.removeEventListener('click', () => {
                this.onHover(element)
            })
            element.removeEventListener('mouseleave', () => {
                this.onBlur(element)
            })
        })
    }

    onScroll = () => {
        this.setState({ isScrolling: true })

        if (this.timeout) {
            clearTimeout(this.timeout)
        }

        if (this.state.currentPageHeight !== document.body.offsetHeight) {
            this.onResize()
            return
        }

        if (!this.state.isNavigating) {
            let isSearching = true

            for (let index = 0; isSearching; index++) {
                const beforePos = this.state.sectionPositions[index - 1]
                const afterPos = this.state.sectionPositions[index + 1]

                if (
                    (window.scrollY === 0 && index === 0) ||
                    (window.scrollY >
                        (beforePos >= 0 ? beforePos : window.scrollY + 1) &&
                        window.scrollY <
                            (afterPos >= 0 ? afterPos : window.scrollY - 1)) ||
                    window.innerHeight + window.scrollY >=
                        document.body.offsetHeight
                ) {
                    this.setState({
                        currentIndicatorPosition:
                            (this.state.links[index] as HTMLAnchorElement)
                                .offsetLeft + 15,
                    })
                }

                if (index + 1 >= this.state.sectionPositions.length) {
                    isSearching = false
                }
            }
        }

        this.timeout = setTimeout(() => {
            this.setState({ isScrolling: false, isNavigating: false })
        }, 500)
    }

    onResize = () => {
        this.setState({ currentPageHeight: document.body.offsetHeight })
        this.removeListeners()
        this.configLinks()
        this.configSections()
    }

    onHover = (link: Element) => {
        const indicator = document.getElementById('nav-indicator')

        if (!link || !indicator) {
            return
        }

        const { offsetLeft: linkPosition } = link as HTMLAnchorElement
        indicator.setAttribute('style', 'left: ' + (linkPosition + 15) + 'px')
    }

    onBlur = (link: Element) => {
        const indicator = document.getElementById('nav-indicator')

        if (!link || !indicator) {
            return
        }

        indicator.setAttribute(
            'style',
            'left: ' + this.state.currentIndicatorPosition + 'px'
        )
    }

    onClick = (link: Element) => {
        const indicator = document.getElementById('nav-indicator')

        if (!link || !indicator) {
            return
        }

        const { offsetLeft: linkPosition } = link as HTMLAnchorElement

        this.setState({
            isNavigating: true,
            currentIndicatorPosition: linkPosition + 15,
        })
    }

    handleMenuClick = () => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen })
    }

    render() {
        return (
            <header className="w-full px-8 py-4 top-0 left-0 border-b border-purple-700 fixed flex justify-between bg-opacity-60 backdrop-filter backdrop-blur z-10">
                <a href="#inicio">
                    <img
                        src={logoImg}
                        alt="Idac"
                        className={
                            window.scrollY > 80
                                ? 'xl:absolute block'
                                : 'xl:hidden'
                        }
                    />
                </a>
                <nav
                    className={`navbar w-full left-0 absolute top-20 py-4 flex-col items-center xl:relative xl:top-0 xl:py-0 xl:flex-row xl:justify-end ${
                        this.state.isMenuOpen ? 'flex' : 'hidden xl:flex'
                    }`}
                >
                    {this.state.linkList.map(
                        (link: IHeaderLink, index: number) => {
                            return (
                                <Link
                                    key={index}
                                    href={link.url}
                                    className="navlink px-5 py-4 xl:py-2 text-white capitalize"
                                    label={link.label}
                                    onClick={this.handleMenuClick}
                                />
                            )
                        }
                    )}

                    <span
                        id="nav-indicator"
                        className="absolute bottom-0 duration-100 hidden top-14 underline w-28 xl:block"
                        style={{
                            left: `${this.state.currentIndicatorPosition}px`,
                        }}
                    ></span>
                </nav>
                <img
                    src={this.state.isMenuOpen ? closeLineImg : menuLineImg}
                    alt="Icono menú"
                    onClick={this.handleMenuClick}
                    className="block xl:hidden"
                />
            </header>
        )
    }
}
