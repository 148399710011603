import { Component } from 'react'

interface ILink {
    href: string
    label: string
    className: string
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

export class Link extends Component<ILink> {
    render() {
        const { href, label, className, onClick } = this.props
        return (
            <a className={className} href={href} onClick={onClick}>
                {label}
            </a>
        )
    }
}
