import { Component } from 'react'

import './Services.css'

import strategy from '../../../images/strategy.svg'
import deploy from '../../../images/deploy.svg'
import evolution from '../../../images/evolution.svg'
import implementation from '../../../images/implementation.svg'

export class Services extends Component {
    render() {
        return (
            <section id="metodologia" className="page-section py-5 lg:py-20">
                <h2 className="text-white text-4xl uppercase font-bold text-center pt-10">
                    metodología
                </h2>

                <div
                    className="map mt-24 lg:mt-48 mb-5 lg:mb-32 flex flex-col lg:flex-row bg-no-repeat mx-auto max-w-screen-2xl
"
                >
                    <div className="w-full lg:w-1/4 h-60 flex items-start justify-center relative">
                        <div
                            className="point point-top rounded-full w-5 h-5 absolute"
                            data-aos="zoom-in"
                            data-aos-delay="500"
                        ></div>
                        <span className="text-desc text-center block text-pink-500 uppercase text-2xl mt-0 lg:-mt-20">
                            estrategia
                        </span>
                        <img
                            className="absolute top-16 h-20"
                            src={strategy}
                            alt="estrategia"
                        />
                    </div>
                    <div className="w-full lg:w-1/4 h-60 flex items-start lg:items-end justify-center relative">
                        <div
                            className="point point-bottom rounded-full w-5 h-5 absolute"
                            data-aos="zoom-in"
                            data-aos-delay="1000"
                            data-aos-offset="-50"
                        ></div>

                        <span className="text-desc text-center block text-pink-500 uppercase text-2xl mt-0 lg:-mb-20">
                            implementación
                        </span>

                        <img
                            className="absolute top-16 lg:bottom-16 h-20"
                            src={implementation}
                            alt="estrategia"
                        />
                    </div>
                    <div className="w-full lg:w-1/4 h-60 flex items-start justify-center relative">
                        <div
                            className="point point-top rounded-full w-5 h-5 absolute"
                            data-aos="zoom-in"
                            data-aos-delay="1500"
                        ></div>
                        <span className="text-desc text-center block text-pink-500 uppercase text-2xl mt-0 lg:-mt-20">
                            despliegue
                        </span>
                        <img
                            className="absolute top-16 h-20"
                            src={deploy}
                            alt="estrategia"
                        />
                    </div>
                    <div className="w-full lg:w-1/4 h-60 flex items-start lg:items-end justify-center relative">
                        <div
                            className="point point-bottom rounded-full w-5 h-5 absolute"
                            data-aos="zoom-in"
                            data-aos-delay="2000"
                            data-aos-offset="-50"
                        ></div>
                        <span className="text-desc text-center block text-pink-500 uppercase text-2xl mt-0 lg:-mb-20">
                            evolutivo
                        </span>
                        <img
                            className="absolute top-16 lg:bottom-16 h-20"
                            src={evolution}
                            alt="estrategia"
                        />
                    </div>
                </div>
            </section>
        )
    }
}
