import { createRoot } from 'react-dom/client'

import Layout from './components/layout/Layout'

import reportWebVitals from './reportWebVitals'

import './index.css'

const container = document.getElementById('app')
const root = createRoot(container)

root.render(<Layout />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
