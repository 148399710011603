import { Component } from 'react'

import logoImg from '../../../images/logo-white.svg'
import appImg from '../../../images/app.svg'

export class Home extends Component {
    render() {
        return (
            <section
                id="inicio"
                className="flex flex-col h-2/4 justify-between lg:pt-40 lg:px-16 max-w-screen-2xl md:flex-row md:pt-28 mx-auto page-section pt-10 px-5"
            >
                <div
                    className="flex flex-col align-center"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                >
                    <img
                        src={logoImg}
                        alt="Idac logo"
                        className="px-5 hidden lg:flex"
                    />
                    <span
                        className="underline mt-10 w-40 mx-auto md:mx-0 hidden lg:block"
                        data-aos="fade-left"
                        data-aos-duration="2000"
                    ></span>

                    <h1 className="mt-10 pt-10 xl:pt-0 text-center md:text-left">
                        <span className="uppercase block text-white text-2xl">
                            desarrollamos & transformamos
                        </span>

                        <span className="uppercase text-pink-500 block text-4xl font-bold mt-6 md:mt-2">
                            ideas en realidad
                        </span>
                    </h1>
                </div>

                <div
                    className="mt-6 lg:mt-0"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                >
                    <img src={appImg} alt="Idac App" />
                </div>
            </section>
        )
    }
}
